<template>
  <Page>
    <Navbar title="邀请司机" />
    <div class="pa-16 driver-invite-page d-flex flex-column">
      <img :src="require('@/assets/jrcd.png')" alt="" />
      <div class="join-item mt-20 pa-16">
        <div class="d-flex mb-24">
          <div class="text_primary join-item-tag py-4 px-8">方式一</div>
          <div class="font-16 lh-28 ml-16">扫码邀请司机</div>
        </div>
        <Button size="large" @click="handleDriverQRCodeScanClick" type="primary">扫码司机二维码</Button>
      </div>
      <div class="join-item mt-20 pa-16">
        <div class="d-flex mb-24">
          <div class="text_primary join-item-tag py-4 px-8">方式二</div>
          <div class="font-16 lh-28 ml-16">查找司机</div>
        </div>
        <Button size="large" @click="visible = true" type="primary">司机手机号查询 </Button>
      </div>
      <div class="join-item mt-20 pa-16">
        <div class="d-flex mb-24">
          <div class="text_primary join-item-tag py-4 px-8">方式三</div>
          <div class="font-16 lh-28 ml-16">司机扫码加入车队</div>
        </div>
        <Button size="large" @click="handleShowQRCode" type="primary"
          ><Icon name="ewm-b "></Icon> 点击查看二维码
        </Button>
      </div>
      <Popup v-model="visible" @submit="handleSearchDiriverByPhone">
        <div class="carrier-form py-40 px-20">
          <div class="font-16 fw-400">请输入司机手机号</div>
          <van-form class="input-border">
            <van-field
              required
              v-model="driverPhoneRef"
              input-align="left"
              placeholder="请输入手机号"
              :rules="FormObj.rules"
            />
          </van-form>
          <div class="buttons d-flex font-16 text-center lh-48 justify-space-between">
            <div class="button-cancel text_muted fw-500" @click="visible = false">取消</div>
            <div class="button-ok fw-500" @click="handleSearchDiriverByPhone">查询</div>
          </div>
        </div>
      </Popup>
    </div>
  </Page>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { PageEnum } from '@/enums/pageEnum';
import { Button, Popup, Toast } from 'vant';
import { useUserStore } from '@/store/user';
import ClientApp from '@/client/index';

export default defineComponent({
  name: 'driver-invite',
  components: {
    Button,
    Popup,
    Toast
  },
  setup(_, ctx) {
    const visible = ref(false);
    const driverPhoneRef = ref();
    const useUser = useUserStore();
    const FormObj = {
      rules: [
        { required: true, message: '手机号不能为空!' },
        {
          // pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
          pattern: /^[1][0-9]{10}$/,
          message: '手机号不合法!'
        }
      ]
    };
    const handleDriverQRCodeScanClick = async () => {
      //TODO: 原生支持
      try {
        const res = await ClientApp.startScan();
        window.location.href = res;
      } catch (err) {
        Toast.fail(err);
      }
    };

    const handleShowQRCode = () => {
      //TODO: 原生支持
      const { carrierInfoVO } = useUser.userInfo;
      //TODO: 调用司机原生
      ClientApp.showUserQRCode({
        identity: 'CARRIER',
        phone: carrierInfoVO.carrierPhone,
        userName: carrierInfoVO.carrierName,
        teamName: carrierInfoVO.fleetInfoVO.name,
        qrCode: `/driver/fleet/result?phone=${carrierInfoVO.carrierPhone}&identity=CARRIER`
      });
    };
    const handleSearchDiriverByPhone = () => {
      if (FormObj.rules[1].pattern.test(driverPhoneRef.value)) {
        ctx.root.$router.push({
          path: PageEnum.CARRIER_FLEET_INVITE_DRIVER_RESULT,
          query: {
            phone: driverPhoneRef.value
          }
        });
      } else {
        Toast.fail('手机号不合法!');
      }
    };

    return {
      visible,
      FormObj,
      driverPhoneRef,
      PageEnum,
      handleDriverQRCodeScanClick,
      handleShowQRCode,
      handleSearchDiriverByPhone
    };
  }
});
</script>
<style lang="less">
.driver-invite-page {
  background: #f1f3f5;
  min-height: 100vh;
  img {
    height: 1.36rem;
  }
  .join-item {
    width: 100%;
    border-radius: 4px;
    height: 1.4rem;
    background: white;
    box-shadow: 0px 4px 12px 0px rgba(223, 233, 245, 0.2);
    .join-item-tag {
      background: rgba(64, 152, 255, 0.1);
      border-radius: 4px;
    }
  }
  .carrier-form {
    width: 2.7rem;
    height: 2.04rem;
    border-radius: 4px;
    box-shadow: 0px 2px 20px 0px #ebeef2;
    .buttons {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0%;
      height: 0.48rem;
      .button-cancel {
        background: #f6f8fa;
        flex: 1;
      }
      .button-ok {
        background: #0076ff;
        color: white;
        flex: 1;
        box-shadow: 0px 1px 0px 0px rgba(188, 188, 188, 0.5) inset;
      }
    }
  }
}
</style>
